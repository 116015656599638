import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateTeamMember, urlDeleteTeamMember, urlGetTeamMember, urlUpdateTeamMember } from "./CONSTANTS";


export function apiCreateTeamMember(data) {
  const url = urlCreateTeamMember;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("cost_per_hour", data.cost_per_hour);
  formData.append("cost_per_day", data.cost_per_day);
  formData.append("cost_per_week", data.cost_per_week);
  formData.append("cost_per_month", data.cost_per_month);  
  
  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateTeamMember(data) {
  const url = urlUpdateTeamMember;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("cost_per_hour", data.cost_per_hour);
  formData.append("cost_per_day", data.cost_per_day);
  formData.append("cost_per_week", data.cost_per_week);
  formData.append("cost_per_month", data.cost_per_month);  
  
  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetTeamMemberList = () => {
  const url = urlGetTeamMember;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiDeleteTeamMember(id) {
  const url = urlDeleteTeamMember;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}