import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from "antd";
import "./InventoryListTableWidget.css"
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';
import { getCurrencyInfo } from 'utils/GlobalFunctions';

function InventoryListTableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { inventoryList, onUpdateAction, onDeleteAction, } = props;
    if (inventoryList === undefined) return <div />
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        align: 'center',
      },
      {
        title: "Thumbnail",
        dataIndex: "thumbnail_image",
        align: 'center',
        render: (text, record) => {
            return (
                <img className="thumbnail-img" src={record.thumbnail_image}/>
            )
          },
      },
      {
        title: "Name",
        dataIndex: "name",
        align: 'center',
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
        },
      },
      { title: "Description", dataIndex: "description" },
      {
        title: "QTY",
        dataIndex:"qty",
        align: 'center',
        sorter: {
          compare: (a, b) => a.qty - b.qty
        },
      },
      {
        title: "Spoilage",
        dataIndex:"spoilage",
        align: 'center',
        sorter: {
          compare: (a, b) => a.spoilage - b.spoilage
        },
      },
      {
        title: "Cost Per Unit",
        dataIndex:"price_per_unit",
        align: 'center',
        sorter: {
          compare: (a, b) => a.price_per_unit - b.price_per_unit
        },
        render: text => <span>{ "$(" + getCurrencyInfo() + ") " + text}</span>,            
      },
      {
        title: "Unit",
        dataIndex:"unit_name",
        align: 'center',
        sorter: {
          compare: (a, b) => a.unit_name.localeCompare(b.unit_name)
        },
      },
      {
        title: "Category",
        dataIndex:"category_name",
        align: 'center',
        sorter: {
          compare: (a, b) => a.category_name.localeCompare(b.category_name)
        },
      },
      {
        title: "Location",
        dataIndex:"location_name",
        align: 'center',
        sorter: {
          compare: (a, b) => a.location_name.localeCompare(b.location_name)
        },
      },
       {
        title: "Created Date",
        dataIndex: "add_timestamp",
        align: 'center',
        sorter: {
          compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
        },
      },
      {
        title: "Updated Date",
        dataIndex: "update_timestamp",
        align: 'center',
        sorter: {
          compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
        },
      },
      {
        title: "Action",
        key: "action",
        align: 'center',
        render: (text, record) => {
          return showColumnAction(text, record, onUpdateAction, onDeleteAction)
        },
      },
    ];
    const inventoryDataSource = inventoryList.map((info) => {
      return { ...info, key: info.id, name: info.name.name, category_name: info.category.name, unit_name: info.unit.name, location_name: info.location.name };
    });
    return (
    <div className="list-table">
        <Table
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50']}}
          columns={columns}
          dataSource={inventoryDataSource}
        />
      </div>
    )
}
export default withRouter(InventoryListTableWidget)

