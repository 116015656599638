import { googleKey } from "config/CONSTANTS";
export const BASE_URL = "https://fnb-max.com/backend/admin";
//export const BASE_URL = "http://127.0.0.1/cafe/admin";

export const urlUserLogin = BASE_URL + "/Main/login";

export const urlGetInventoryList = BASE_URL + "/Inventory/all";
export const urlCreateIventory = BASE_URL + "/Inventory/addInventory";
export const urlUpdateIventory = BASE_URL + "/Inventory/updateInventory";
export const urlDeleteIventory = BASE_URL + "/Inventory/deleteInventory";
export const urlAppendIventory = BASE_URL + "/Inventory/appendInventory";


export const urlInventoryBasicData = BASE_URL + "/Inventory/getBasicInfo";

export const urlCreateInventoryCategory = BASE_URL + "/Inventory/createCategory";
export const urlGetInventoryCategory = BASE_URL + "/Inventory/getCategory";
export const urlUpdateIventoryCategory = BASE_URL + "/Inventory/updateCategory";
export const urlDeleteIventoryCategory = BASE_URL + "/Inventory/deleteCategory";

export const urlCreateInventoryLocation = BASE_URL + "/Inventory/createLocation";
export const urlGetInventoryLocation = BASE_URL + "/Inventory/getLocation";
export const urlUpdateIventoryLocation = BASE_URL + "/Inventory/updateLocation";
export const urlDeleteIventoryLocation = BASE_URL + "/Inventory/deleteLocation";

export const urlCreateInventoryUnit = BASE_URL + "/Inventory/createUnit";
export const urlGetInventoryUnit = BASE_URL + "/Inventory/getUnit";
export const urlUpdateIventoryUnit = BASE_URL + "/Inventory/updateUnit";
export const urlDeleteIventoryUnit = BASE_URL + "/Inventory/deleteUnit";

export const urlCreateInventoryName = BASE_URL + "/Inventory/createName";
export const urlGetInventoryName = BASE_URL + "/Inventory/getName";
export const urlUpdateInventoryName = BASE_URL + "/Inventory/updateName";
export const urlDeleteInventoryName = BASE_URL + "/Inventory/deleteName";

export const urlGetInventoryGroupByName = BASE_URL + "/InventoryGroup/getInventoryByName";

export const urlCreateRecipe = BASE_URL + "/Recipe/createRecipe";
export const urlGetRecipeList = BASE_URL + "/Recipe/getRecipeList";
export const urlDeleteRecipe = BASE_URL + "/Recipe/deleteRecipe";
export const urlGetRecipeById = BASE_URL + "/Recipe/getRecipeById";
export const urlUpdateRecipe = BASE_URL + "/Recipe/updateRecipe";
export const urlRecipeBasicData = BASE_URL + "/Recipe/getBasicInfo";

export const urlGetRecipeCategory = BASE_URL + "/Recipe/getCategory";
export const urlCreateRecipeCategory = BASE_URL + "/Recipe/createCategory";
export const urlUpdateRecipeCategory = BASE_URL + "/Recipe/updateCategory";
export const urlDeleteRecipeCategory = BASE_URL + "/Recipe/deleteCategory";

export const urlGetRecipeLocation = BASE_URL + "/Recipe/getLocation";
export const urlCreateRecipeLocation = BASE_URL + "/Recipe/createLocation";
export const urlUpdateRecipeLocation = BASE_URL + "/Recipe/updateLocation";
export const urlDeleteRecipeLocation = BASE_URL + "/Recipe/deleteLocation";



export const urlYieldGetBasicInfo = BASE_URL + "/InventoryRule/getBasicInfo";
export const urlCreateYieldName = BASE_URL + "/YieldT/createName";
export const urlCreateYieldRule = BASE_URL + "/InventoryRule/createRule";
export const urlUpdateYieldRule = BASE_URL + "/InventoryRule/updateRule";
export const urlGetYieldRuleByInvNameId = BASE_URL + "/YieldT/getRuleByInvNameId";
export const urlGetIngredient = BASE_URL + "/InventoryRule/getIngredient";
export const urlGetYieldRuleList = BASE_URL + "/InventoryRule/getYieldRuleList";
export const urlDeleteYieldRule = BASE_URL + "/InventoryRule/deleteYieldRule";
export const urlgetYieldRuleByID = BASE_URL + "/InventoryRule/getYieldRuleByID";

export const urlGetSalesList = BASE_URL + "/Sales/getSalesList";
export const urlMakePayWithPaypal = BASE_URL + "/Sales/makePayWithPaypal";

export const urlGetPayHistoryList = BASE_URL + "/PayHistory/getPayHistory";
export const urlGetPayHistoryListWithDate = BASE_URL + "/PayHistory/getPayHistoryWithDate";

export const urlGetOrderList = BASE_URL + "/Order/getOrderList";
export const urlApproveOrder = BASE_URL + "/Order/approveOrder";
export const urlDeliverOrder = BASE_URL + "/Order/deliverOrder";
export const urlPaymentConfirmOrder = BASE_URL + "/Order/paymentConfirmOrder";


export const urlGetUnitBasicInfo = BASE_URL + "/UnitConvert/getBasicInfo";

export const urlCreateCustomUnitConvert = BASE_URL + "/UnitConvert/createCustomUnitConvert";
export const urlDeleteCustomUnitConvert = BASE_URL + "/UnitConvert/deleteCustomUnitConvert";
export const urlGetCustomUnitConvertList = BASE_URL + "/UnitConvert/getCustomUnitConvertList";
export const urlUpdateCustomUnitConvert = BASE_URL + "/UnitConvert/updateCustomUnitConvert";


export const urlGetGlobalUnitConvertList = BASE_URL + "/UnitConvert/getGlobalUnitConvertList";
export const urlDeleteGlobalUnitConvert = BASE_URL + "/UnitConvert/deleteGlobalUnitConvert";
export const urlCreateGlobalUnitConvert = BASE_URL + "/UnitConvert/createGlobalUnitConvert";
export const urlUpdateGlobalUnitConvert = BASE_URL + "/UnitConvert/updateGlobalUnitConvert";

export const urlGetProfileInfo = BASE_URL + "/Profile/GetProfileInfo";
export const urlUpdateProfileInfo = BASE_URL + "/Profile/UpdateProfileInfo";

export const urlGetDashboardDetail = BASE_URL + "/DashBoard/GetDashboardDetail";

export const urlCreateSpoilage = BASE_URL + "/Spoilage/createSpoilage";
export const urlGetSpoilageList = BASE_URL + "/Spoilage/getSpoilageList";
export const urlDeleteSpoilage = BASE_URL + "/Spoilage/deleteSpoilage";

export const urlCreateSalableType = BASE_URL + "/SalableType/createSalableType";
export const urlGetSalableType = BASE_URL + "/SalableType/getSalableType";
export const urlUpdateSalableType = BASE_URL + "/SalableType/updateSalableType";
export const urlDeleteSalableType = BASE_URL + "/SalableType/deleteSalableType";

export const urlCreateEnt = BASE_URL + "/Ent/createEnt";
export const urlGetEntList = BASE_URL + "/Ent/getEntList";
export const urlDeleteEnt = BASE_URL + "/Ent/deleteEnt";

export const urlCreateTeamMember = BASE_URL + "/TeamMember/createTeamMember";
export const urlGetTeamMember = BASE_URL + "/TeamMember/getTeamMember";
export const urlUpdateTeamMember = BASE_URL + "/TeamMember/updateTeamMember";
export const urlDeleteTeamMember = BASE_URL + "/TeamMember/deleteTeamMember";

export const urlCreateInvestment = BASE_URL + "/Investment/createInvestment";
export const urlGetInvestment = BASE_URL + "/Investment/getInvestment";
export const urlUpdateInvestment = BASE_URL + "/Investment/updateInvestment";
export const urlDeleteInvestment = BASE_URL + "/Investment/deleteInvestment";

export const urlCreateExpense = BASE_URL + "/Expense/createExpense";
export const urlGetExpense = BASE_URL + "/Expense/getExpense";
export const urlUpdateExpense = BASE_URL + "/Expense/updateExpense";
export const urlDeleteExpense = BASE_URL + "/Expense/deleteExpense";

export const urlCreateSalary = BASE_URL + "/Salary/createSalary";
export const urlGetSalary = BASE_URL + "/Salary/getSalary";
export const urlDeleteSalary = BASE_URL + "/Salary/deleteSalary";

export const urlUpdateSetting = BASE_URL + "/Setting/updateSetting";
export const urlGetSetting = BASE_URL + "/Setting/getSetting";

export const urlGenerateReport = BASE_URL + "/Report/generateReport";
export const urlSalesAnalysis = BASE_URL + "/Report/salesAnalysis";
export const urlInventoryReport = BASE_URL + "/Report/InventoryReport";

export const urlCreateRoom = BASE_URL + "/Room/createRoom";
export const urlGetRoomList = BASE_URL + "/Room/getRoomList";
export const urlDeleteRoom = BASE_URL + "/Room/deleteRoom";
export const urlGetRoomById = BASE_URL + "/Room/getRoomById";
export const urlUpdateRoom = BASE_URL + "/Room/updateRoom";
export const urlUpdateRoomDiagram = BASE_URL + "/Room/UpdateRoomDiagram";

export const urlCreateUserSiteMainCategory = BASE_URL + "/UserSiteMainCategory/createCategory";
export const urlUpdateUserSiteMainCategory = BASE_URL + "/UserSiteMainCategory/updateCategory";
export const urlGetUserSiteMainCategoryList = BASE_URL + "/UserSiteMainCategory/getCategoryList";
export const urlDeleteUserSiteMainCategory = BASE_URL + "/UserSiteMainCategory/deleteCategory";
export const urlUpdateUserSiteMainCategoryPosition = BASE_URL + "/UserSiteMainCategory/updateCategoryPosition";


export const urlCreateUserSiteSubCategory = BASE_URL + "/UserSiteSubCategory/createCategory";
export const urlUpdateUserSiteSubCategory = BASE_URL + "/UserSiteSubCategory/updateCategory";
export const urlGetUserSiteSubCategoryList = BASE_URL + "/UserSiteSubCategory/getCategoryList";
export const urlDeleteUserSiteSubCategory = BASE_URL + "/UserSiteSubCategory/deleteCategory";
export const urlUpdateUserSiteSubCategoryPosition = BASE_URL + "/UserSiteSubCategory/updateCategoryPosition";

export const urlAddUserSiteItemMainSub = BASE_URL + "/UserSiteItemMainSub/AddItem";
export const urlUpdateUserSiteItemMainSub = BASE_URL + "/UserSiteItemMainSub/UpdateItem";
export const urlGetUserSiteItemMainSubList = BASE_URL + "/UserSiteItemMainSub/getItemList";
export const urlGetUserSiteItemMainSubByID = BASE_URL + "/UserSiteItemMainSub/getItemByID";
export const urlDeleteUserSiteItemMainSub = BASE_URL + "/UserSiteItemMainSub/deleteItem";
export const urlGetUserSiteItemRecipeList = BASE_URL + "/UserSiteItemMainSub/getRecipeList";
export const urlUpdateUserSiteItemMainSubPosition = BASE_URL + "/UserSiteItemMainSub/updateItemPosition";


export const urlCreateUser = BASE_URL + "/Profile/createUser";
export const urlUpdateUser = BASE_URL + "/Profile/updateUser";
export const urlGetUserList = BASE_URL + "/Profile/getUserList";
export const urlDeleteUser = BASE_URL + "/Profile/deleteUser";