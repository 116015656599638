import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import dragula from "dragula";
import "dragula/dist/dragula.css";
import { useEffect } from 'react';
import { DragOutlined } from '@ant-design/icons';
import { apiUpdateUserSiteItemMainSubPosition } from 'services/userSiteItemMainSubService';

function TableWidgetRecipeExtra(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, onDeleteAction } = props;

    function createMarkup(html) {
        return { __html: html };
    }

    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Thumbnail",
            dataIndex: "thumbnail_image",
            render: (text, record) => {
                if (record.thumbnail_image === null) {
                    return (
                        <img className="room-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                    )
                }
                else {
                    return (
                        <img className="room-thumbnail-img" src={record.thumbnail_image} />
                    )
                }

            },
        },
        {
            title: "Recipe Name",
            dataIndex: "name",
            align: "center",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            align: "center",
            sorter: {
                compare: (a, b) => a.description.localeCompare(b.description),
            },
            render: (text, record) => {
                return <div dangerouslySetInnerHTML={createMarkup(record.description)} />;
            },
        },
        {
            title: "Updated At",
            dataIndex: "update_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                            if (res == false) return;
                            onDeleteAction(record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];

    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidgetRecipeExtra)
