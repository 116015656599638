import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateExpense, urlDeleteExpense, urlGetExpense, urlUpdateExpense } from "./CONSTANTS";


export function apiCreateExpense(data) {
  const url = urlCreateExpense;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("cost_per_month", data.cost_per_month);
  formData.append("month_date", data.month_date);

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }

  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateExpense(data) {
  const url = urlUpdateExpense;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("name", data.name);
  formData.append("cost_per_month", data.cost_per_month);
  formData.append("month_date", data.month_date);

  if (data.image_name instanceof File) {
    formData.append("image_name", data.image_name);
  }
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetExpenseList = () => {
  const url = urlGetExpense;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiDeleteExpense(id) {
  const url = urlDeleteExpense;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}