import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList, onUpdateAction, onDeleteAction, onDetailAction } = props;


    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Thumbnail",
            dataIndex: "thumbnail_image",
            render: (text, record) => {
                if (record.thumbnail_image === null) {
                    return (
                        <img className="room-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                    )
                }
                else {
                    return (
                        <img className="room-thumbnail-img" src={record.thumbnail_image} />
                    )
                }

            },
        },
        {
            title: "Room Name",
            dataIndex: "name",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Width(m)",
            dataIndex: "width",
            sorter: {
                compare: (a, b) => a.width.localeCompare(b.width),
            },
        },
        {
            title: "Height(m)",
            dataIndex: "height",
            sorter: {
                compare: (a, b) => a.height.localeCompare(b.height),
            },
        },
        {
            title: "Block Status",
            dataIndex: "status",
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status),
            },
            render: (text, record) => {
                if (record.status === "1") {
                    return <div className="active-btn">Yes</div>
                }
                else {
                    return <div className="inactive-btn">No</div>
                }
            }
        },
        {
            title: "Reservation Start At",
            dataIndex: "reservation_start_timestamp",
            sorter: {
                compare: (a, b) => a.reservation_start_timestamp.localeCompare(b.reservation_start_timestamp),
            },
        },
        {
            title: "Reservation End At",
            dataIndex: "reservation_end_timestamp",
            sorter: {
                compare: (a, b) => a.reservation_end_timestamp.localeCompare(b.reservation_end_timestamp),
            },
        },
        {
            title: "Reservation Name",
            dataIndex: "reservation_name",
            sorter: {
                compare: (a, b) => a.reservation_name.localeCompare(b.reservation_name),
            },
        },
        {
            title: "Updated At",
            dataIndex: "update_timestamp",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align : "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a  className="edit-btn"
                            onClick={() => {
                                onDetailAction(record)
                            }}
                        >
                            Detail
                        </a>
                        <a style={{color: "var(--orangeColor)", marginLeft: 20 }} className="edit-btn"
                            onClick={() => {
                                onUpdateAction(record)
                            }}
                        >
                            Edit
                        </a>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                            if (res == false) return;
                            onDeleteAction(record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidget)
