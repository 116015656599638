import React from "react";
import { Row, Spin, Col, DatePicker, Space, message, Radio } from 'antd';
import "./SalesAnalysis.css";
import { useState } from "react";
import { useEffect } from "react";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import moment from "moment"
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { apiSalesAnalysis } from "services/reportService";
import TableWidget from "./TableWidget/TableWidget";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactExport from "react-export-excel";
import { empty, getCurrencyInfo, isEmpty } from "utils/GlobalFunctions";
// import { read, writeFileXLSX } from "xlsx";
// import { set_cptable } from "xlsx";
// import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
// set_cptable(cptable);
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
function SalesAnalysis() {
    const [isBusy, setIsBusy] = useState(false);
    const [data, setData] = useState({})
    const [dataList, setDataList] = useState([]);

    useEffect(() => {

    }, []);

    const onChangeRadio = e => {
        var tmpData = data
        tmpData["selected_date_period"] = e.target.value
        tmpData["start_date"] = ""
        tmpData["end_date"] = ""
        setData({ ...tmpData })
    }

    function onChangeDatePicker(date, dateString) {
        setDataList([])
        if (!dateString) {
            message.info("Please select date")
            return
        }
        if (data["selected_date_period"] === "range_date") {
            var tmpData = data
            tmpData["start_date"] = dateString[0]
            tmpData["end_date"] = dateString[1]
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "date") {
            var tmpData = data
            tmpData["start_date"] = dateString
            tmpData["end_date"] = dateString
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "week") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('week').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('week').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "month") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('month').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('month').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "quarter") {
            var tmpData = data
            tmpData["start_date"] = moment(date).startOf('quarter').format(dateFormat)
            tmpData["end_date"] = moment(date).endOf('quarter').format(dateFormat)
            setData({ ...tmpData })
        } else if (data["selected_date_period"] === "year") {
            var tmpData = data
            tmpData["start_date"] = moment(dateString).startOf('year').format(dateFormat)
            tmpData["end_date"] = moment(dateString).endOf('year').format(dateFormat)
            setData({ ...tmpData })
        }
        else {
            var tmpData = data
            tmpData["start_date"] = ""
            tmpData["end_date"] = ""
            setData({ ...tmpData })
        }

        onGetSalesData()
    }

    const onGetSalesData = () => {
        console.log(data)
        if (!data["start_date"] || !data["end_date"]) {
            message.info("Please select date")
            return
        }
        setIsBusy(true);
        apiSalesAnalysis(data)
            .then((data) => {
                setIsBusy(false);
                prepareData(data)
            })
            .catch((err) => {
                setIsBusy(false);
                prepareData([])
                message.error(err);
            });
    }

    const prepareData = (res) => {
        let salableTypeList = res["salableTypeList"];
        if (!salableTypeList) return;

        let resultData = res["result_data"];
        if (empty(resultData)) return;

        let tmpList = []
        for (let i = 0; i < salableTypeList.length; i++) {
            let tmp = { id: salableTypeList[i]["id"], name: salableTypeList[i]["name"] }
            if (resultData[salableTypeList[i]["id"]]) {
                let soldItems = [];
                let soldQuantities = Object.keys(resultData[salableTypeList[i]["id"]]);
                soldQuantities.reverse().map((soldQuantity, index) => {
                    let items = resultData[salableTypeList[i]["id"]][soldQuantity];
                    let soldItem = { id: index, title: `Sold Quantity(${soldQuantity})`, items: items };
                    soldItems.push(soldItem);
                });

                tmp["items"] = soldItems;
            } else {
                tmp["items"] = [];
            }
            tmpList.push(tmp)
        }
        console.log("dataList:", tmpList)
        setDataList(tmpList)

        let tmpData = data
        let settingList = res["settingList"]
        if (settingList) {
            for (let i = 0; i < settingList.length; i++) {
                tmpData[settingList[i]["option_name"]] = settingList[i]["option_value"]
            }
        }
        tmpData["numberOfDays"] = res["numberOfDays"]
        setData({ ...tmpData })
    }

    /* const getTotalSales = () => {
        let res = data["totalSalesMain"]
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res
    }

    const getRent = () => {
        let rent = data["rent"]
        let revenue = data["totalSalesMain"]
        if (!rent || !revenue) {
            return 0
        }
        return (revenue / 100 * rent).toFixed(2)
    }

    const getTeamCost = () => {
        let teamCost = data["teamCost"]
        let numberOfDays = data["numberOfDays"]
        if (!teamCost || !numberOfDays) {
            return 0
        }
        return (teamCost / 30 * numberOfDays).toFixed(2)
    }

    const getExpensePowerGasOil = () => {
        let expense = data["expense_power_gas_oil"]
        let numberOfDays = data["numberOfDays"]
        if (!expense || !numberOfDays) {
            return 0
        }
        return (expense / 30 * numberOfDays).toFixed(2)
    }

    const getTaxVat = () => {
        let res = getTotalSales() * data["tax_vat"] / 100
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res
    }

    const getGrossFit = () => {
        let res = (getTotalSales() - data["totalCogs"] - getRent() - getTeamCost() - getExpensePowerGasOil() - getTaxVat()).toFixed(5)
        if (res === undefined || isNaN(res) || res === null) {
            return 0
        }
        return res
    }*/

    const exportPDF = () => {
        if (dataList.length == 0 || !data) {
            message.info("Firstly, Please select date. Then, try again")
            return
        }
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Sales Analysis Data from " + data["start_date"] + " to " + data["end_date"];
        const headers = [["", "", "ID", "Name", "QTY", "Total Cost", "Created Date", "Updated Date"]];

        let fistLabel = "";
        let secondLabel = "";
        let pdf_data = []
        dataList.map((e) => {
            e.items.map((x) => {
                
                x.items.map((y) => {
                    let tmpFirstLabel = ""
                    if (fistLabel != e.name) {
                        tmpFirstLabel = e.name
                        fistLabel = e.name
                    }
                    let tmpSecondLabel = ""
                    if (secondLabel != x.title) {
                        tmpSecondLabel = x.title
                        secondLabel = x.title
                    }
                    let cost = "$(" + getCurrencyInfo() + ") " + y.fixed_sell_price;

                    pdf_data.push([tmpFirstLabel, tmpSecondLabel, y.id, y.name, y.qty, cost, y.add_timestamp, y.add_timestamp])
                })
            })
        })


        doc.setFontSize(16);
        doc.setTextColor(40);
        doc.text(title, marginLeft, 60);


        let content = {
            startY: 80,
            head: headers,
            body: pdf_data
        };

        doc.autoTable(content);

        doc.setFontSize(10)
        let finalY = doc.lastAutoTable.finalY + 20; // The y position on the page
        // doc.text(marginLeft, finalY, "Total Sales : $" + getTotalSales())
        // doc.text(marginLeft, finalY + 15, "Rent : $" + getRent())
        // doc.text(marginLeft, finalY + 30, "Team Cost : $" + getTeamCost())
        // doc.text(marginLeft, finalY + 45, "Expenses Power, Gas, Oil : $" + getExpensePowerGasOil())
        // doc.text(marginLeft, finalY + 60, "Tax VAT : $" + getTaxVat())
        // doc.text(marginLeft, finalY + 75, "Gross Profit : $" + getGrossFit())

        doc.setFontSize(12)

        doc.text(marginLeft, finalY + 100, "Generation Date : " + moment().format(dateFormat))

        doc.save("sales_analysis.pdf")
    }
    const exportExcel = () => {
        if (dataList.length == 0 || !data) {
            message.info("Firstly, Please select date. Then, try again")
            return
        }
        let wb = XLSX.utils.book_new();
        dataList.map((e) => {
            let tmpData = []
            tmpData.push(["", "", "ID", "Thumbnail", "Name", "QTY", "Total Cost", "Created Date", "Updated Date"])
            e.items.map((x) => {
                tmpData.push([])
                tmpData.push([x.title])
                x.items.map((y) => {
                    let cost = "$(" + getCurrencyInfo() + ") " + y.fixed_sell_price;
                    tmpData.push(["", "", y.id, y.thumbnail_image, y.name, y.qty, cost, y.add_timestamp, y.add_timestamp])
                })
            })
            let ws = XLSX.utils.aoa_to_sheet(tmpData);

            XLSX.utils.book_append_sheet(wb, ws, e.name);
        })

        XLSX.writeFile(wb, "sales_analysis.xlsx");
    }

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <Row justify="space-between">
                    <Col>
                        <div className="text-header">Sales Report</div>
                    </Col>
                    <Col>
                        {/* <ExcelFile filename={"report"} element={<CommonButton style={{ margin: 10 }}>Export XLXS</CommonButton>}>
                            <ExcelSheet dataSet={exportExcel()} name={moment().format(dateFormat)}></ExcelSheet>
                        </ExcelFile> */}
                        <CommonButton onClick={() => {
                            exportExcel()
                        }} style={{ margin: 10 }}>Export XLXS</CommonButton>
                        <CommonButton onClick={() => {
                            exportPDF()
                        }} style={{ margin: 10 }}>Export PDF</CommonButton>
                        {/* <CommonButton onClick={() => {
                            onGetSalesData()
                        }}>Generate</CommonButton> */}
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <Radio.Group onChange={onChangeRadio} value={data["selected_date_period"]}>
                        <Space>
                            <Radio value={"date"}>
                                <Col> Select date </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} disabled={data["selected_date_period"] === "date" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"week"}>
                                <Col> Select week </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="week" disabled={data["selected_date_period"] === "week" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"month"}>
                                <Col> Select month </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="month" disabled={data["selected_date_period"] === "month" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"quarter"}>
                                <Col> Select quarter </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="quarter" disabled={data["selected_date_period"] === "quarter" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"year"}>
                                <Col> Select year </Col>
                                <span> <DatePicker onChange={onChangeDatePicker} picker="year" disabled={data["selected_date_period"] === "year" ? false : true} /> </span>
                            </Radio>
                            <Radio value={"range_date"}>
                                <Col> Select range date </Col>
                                <span> <RangePicker ranges={{
                                    "Today": [moment(), moment()],
                                    "This Week": [moment().startOf("week"), moment().endOf("week")],
                                    "This Month": [
                                        moment().startOf("month"),
                                        moment().endOf("month"),
                                    ],
                                    "This Quarter": [
                                        moment().startOf("quarter"),
                                        moment().endOf("quarter"),
                                    ],
                                    "This Year": [
                                        moment().startOf("year"),
                                        moment().endOf("year"),
                                    ],
                                }} onChange={onChangeDatePicker} disabled={data["selected_date_period"] === "range_date" ? false : true} /> </span>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Row>
                <div className="type-list">
                    {empty(dataList) ?
                        <>
                            <div style={{ marginTop: 30 }}>
                                <TableWidget
                                    dataList={[]}
                                />
                            </div>
                        </>
                        :
                        dataList.map((data, index) => {
                            return (
                                <div style={{ marginTop: 30 }} key={index}>
                                    <div className="text-header">{`${data.id} ${data.name}`}</div>
                                    <TableWidget
                                        dataList={data.items}
                                    />
                                </div>);
                        })
                    }
                </div>


                {/* <div style={{ marginTop: 10 }}>
                    <Row justify="space-around">
                        <Col>
                            <div>Total Sales :</div>
                            <div>${getTotalSales()}</div>
                        </Col>
                        <Col>
                            <div>Rent : </div>
                            <div>${getRent()}</div>
                        </Col>
                        <Col>
                            <div>Team Cost : </div>
                            <div>${getTeamCost()}</div>
                        </Col>
                        <Col>
                            <div>Expenses Power, Gas, Oil : </div>
                            <div>${getExpensePowerGasOil()}</div>
                        </Col>
                        <Col>
                            <div>Tax VAT : </div>
                            <div>${getTaxVat()}</div>
                        </Col>
                        <Col>
                            <div>Gross Profit : </div>
                            <div>${getGrossFit()}</div>
                        </Col>
                    </Row>
                </div> */}
            </Spin>
        </CommonDivWidget>

    );
}

export default SalesAnalysis;
