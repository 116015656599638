import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, } from "antd";
import { showColumnAction } from 'pages/CategoryPage/CategoryListTableWidget/CategoryListTableColumn';
import { getCurrencyInfo } from 'utils/GlobalFunctions';

const DetailTable = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, onUpdateAction, onDeleteAction, } = props;
  if (dataList === undefined) return <div />
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail_image",
      render: (text, record) => {
        return (
          <img className="detail-widget-image" src={record.thumbnail_image} />
        )
      },
    },
    { title: "Name", dataIndex: "name" },
    {
      title: "QTY",
      dataIndex: "qty",
      sorter: {
        compare: (a, b) => a.qty - b.qty
      },
    },
    {
      title: "Total Cost",
      dataIndex: "fixed_sell_price",
      sorter: {
        compare: (a, b) => a.fixed_sell_price - b.fixed_sell_price
      },
      render: text => <span>{"$(" + getCurrencyInfo() + ") " + text}</span>,
    },
    {
      title: "Created Date",
      dataIndex: "add_timestamp",
      sorter: {
        compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
      },
    },
    {
      title: "Updated Date",
      dataIndex: "update_timestamp",
      sorter: {
        compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
      },
    },
  ]
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={dataList.length > 10 ? { defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] } : false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default withRouter(DetailTable)
