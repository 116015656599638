import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { InputNumber, Table, } from "antd";
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';

const SubRecipeTableWidget = (props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { dataList, errorField, setErrorField, handleChangeQtyforEachItem, ownCommonData } = props;
  function createMarkup(html) {
    return { __html: html };
}
  if (dataList === undefined) return <div />
  const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "Thumbnail",
        dataIndex: "thumbnail_image",
        render: (text, record) => {
            if (record.thumbnail_image === null) {
                return (
                    <img className="thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                )
            }
            else {
                return (
                    <img className="thumbnail-img" src={record.thumbnail_image} />
                )
            }

        },
    },
    {
        title: "Name",
        dataIndex: "name",
        sorter: {
            compare: (a, b) => a.name.localeCompare(b.name),
        },
    },
    {
        title: "QTY",
        dataIndex: "qty",
        sorter: {
            compare: (a, b) => a.qty.localeCompare(b.qty),
        },
        render: text => <span> {parseFloat(text).toFixed(2)}</span>,
    },
    {
        title: "Unit",
        dataIndex: "unit_id",
        align: 'center',
        sorter: {
          compare: (a, b) => a.unit_id - b.unit_id,
        },
        render:(text, record) => {
            const result = ownCommonData.unitList.filter(item => item.id === record.unit_id);
            if (result.length === 0) {
                return "Empty";

            }
            else {
                return result[0].name;
            }
        }
      },
    {
        title: "Total Cost",
        dataIndex: "total_price",
        sorter: {
            compare: (a, b) => a.total_price.localeCompare(b.total_price),
        },
        render: text => <span>$ {parseFloat(text).toFixed(2)}</span>,
    },
    {
        title: "Description",
        dataIndex: "description",
        sorter: {
            compare: (a, b) => a.description.localeCompare(b.description),
        },
        render: (text, record) => {
            return <div dangerouslySetInnerHTML={createMarkup(record.description)} />;
        },
    },
    {
        title: "Author",
        dataIndex: "author",
        sorter: {
            compare: (a, b) => a.author.localeCompare(b.author),
        },
    },
    {
        title: "Salable",
        dataIndex: "salable",
        sorter: {
            compare: (a, b) => a.salable - b.salable,
        },
        render: (text, record) => {
            if (record.salable === "1") {
                return <div>Yes</div>
            }
            else {
                return <div>No</div>
            }
        }
    },
    {
        title: "Sub Recipe",
        dataIndex: "sub_recipe",
        sorter: {
            compare: (a, b) => a.sub_recipe - b.sub_recipe,
        },
        render: (text, record) => {
            if (record.sub_recipe === "1") {
                return <div >Yes</div>
            }
            else {
                return <div>No</div>
            }
        }
    },
    {
        title: "Active",
        dataIndex: "active",
        sorter: {
            compare: (a, b) => a.active - b.active,
        },
        render: (text, record) => {
            if (record.active === "1") {
                return <div >Yes</div>
            }
            else {
                return <div>No</div>
            }
        }
    },
    // {
    //     title: "Created At",
    //     dataIndex: "add_timestamp",
    //     align : "center",
    //     sorter: {
    //         compare: (a, b) => a.name.localeCompare(b.name),
    //     },
    // },
    // {
    //     title: "Updated At",
    //     dataIndex: "update_timestamp",
    //     align: "center",
    //     sorter: {
    //         compare: (a, b) => a.name.localeCompare(b.name),
    //     },
    // },
    {
        title: "Enter QTY",
        key: "enter_qty",
        render: (text, record) => {
  
          return <InputNumber
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "8px",
                background: `var(--backgroundColor)`,
                padding: "4px 12px",
                width: "120px"
            }}
              value={
                record === undefined || record["enter_qty"] == undefined ? "0" : record["enter_qty"]
              }
              defaultValue={0}
              min={"0"}
              step={ "1" }
              onChange={(value) => {
                record["enter_qty"] = value
                handleChangeQtyforEachItem(record)
              }}/>
        },
      },
    {
        title: "Select Unit for QTY",
        key: "enter_unit_id",
        width : 200,
        render: (text, record) => {
          return <ProductTypeSelector
          placeholder={"Select Unit"}
          field={"enter_unit_id"}
          list={ownCommonData.unitList}
          displayField="name"
          idField="id"
          productInfo={record}
          onChangeHandle={(value) => {
            record["enter_unit_id"] = value
            handleChangeQtyforEachItem(record)
          }}
  
        />
        }
      },
    

];
  const dataSource = dataList.map((info) => {
    return { ...info, key: info.id };
  });

  return (
    <div className="list-table">
      <Table
        size="small"
        pagination={false}
        columns={columns}
        dataSource={dataSource}
      />
    </div>
  )
}

export default SubRecipeTableWidget
