import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Space, Radio, Spin } from "antd";

import "./AddSalaryDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import ProductTypeSelector from 'components/ProductPage/ProductTypeSelector/ProductTypeSelector';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import { apiGetTeamMemberList } from 'services/teamMemberService';
function AddSalaryDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [costUnit, setCostUnit] = useState("");

  useEffect(() => {
    apiGetTeamMemberList()
      .then((data) => {
        setDataList(data)
      })
      .catch((err) => {
        // message.error("Sorry! " + err);
      });
  }, []);

  const onChangeHandle = (team_member_id) => {

    let storeIndex = dataList.findIndex(x => x.id == team_member_id);
    var tmpData = dataList[storeIndex]
    tmpData["team_member_id"] = team_member_id
    setData(tmpData)
  }

  const onChangeUnitCost = e => {
    setCostUnit(e.target.value)
    var tmpData = data
    tmpData["selected_cost_unit"] = e.target.value
    setData(tmpData)
  }

  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(data, "team_member_id", errorList);
    errorList = isEmpty(data, "selected_cost_unit", errorList);
    errorList = isEmpty(data, "work_time", errorList);
    errorList = isEmpty(data, "description", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };

  const calcSalaryAmount = ()=>{
    let tmpData = data["work_time"] * data[costUnit]
    if (tmpData == undefined || isNaN(tmpData) || tmpData == null) {
      return 0
    }
    return data["work_time"] * data[costUnit]
  }

  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-salary-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data, "salary_amount" : calcSalaryAmount()
        });
      }}
    >
      <Spin spinning={isBusy}>

        <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
          <Col>
            <div>Select Team Member* </div>
          </Col>
          <Col flex={"auto"}>
            <ProductTypeSelector
              placeholder={"Team Member"}
              field={"team_member_id"}
              list={dataList}
              displayField="name"
              idField="id"
              onChangeHandle={onChangeHandle}
              productInfo={data}
              setProductInfo={setData}
              errorField={errorField}
              setErrorField={setErrorField}
            />
          </Col>
        </Row>

        <Row align={"middle"} gutter={16} style={{ marginTop: "22px" }}>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Row>

        <Row justify='space-around' gutter={16} style={{ marginTop: "17px" }}>
          <Col>
            <div>Choose Time Unit*</div>
          </Col>
          <Col flex={"auto"}>
            <Radio.Group onChange={onChangeUnitCost} value={costUnit}>
              <Space direction="horizontal">
                <Radio value={"cost_per_hour"}>Hour - $ {data['cost_per_hour']}</Radio>
                <Radio value={"cost_per_day"}>Day - $ {data['cost_per_day']}</Radio>
                <Radio value={"cost_per_week"}>Week - $ {data['cost_per_week']}</Radio>
                <Radio value={"cost_per_month"}>Month - $ {data['cost_per_month']}</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: "12px" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <div>Working Time*</div>
            <ProductInputNumber
              field={"work_time"}
              productInfo={data}
              setProductInfo={setData}
              errorField={errorField}
              setErrorField={setErrorField}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <div>Description*</div>
            <ProductDescriptionInput
              placeholder="description"
              field={"description"}
              productInfo={data}
              setProductInfo={setData}
              errorField={errorField}
              setErrorField={setErrorField}
            />
          </Col>
        </Row>

        <Row justify='center' gutter={16} style={{ marginTop: "12px" }}>
          <Col>
            <div>Salary Amount*</div>
          </Col>
        </Row>

        <Row justify='center'>
          <Col>
            <p> $ {calcSalaryAmount()} </p>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddSalaryDialog)
