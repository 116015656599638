import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import dragula from "dragula";
import "dragula/dist/dragula.css";
import { useEffect } from 'react';
import { DragOutlined } from '@ant-design/icons';
import { apiUpdateUserSiteItemMainSubPosition } from 'services/userSiteItemMainSubService';

const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);


function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList, onUpdateAction, onDeleteAction } = props;
    const [dataSource, setDataSource] = useState([]);

    function createMarkup(html) {
        return { __html: html };
    }

    useEffect(() => {
        let start;
        let end;
        const container = document.querySelector(".ant-table-tbody");
        const drake = dragula([container], {
            moves: (el) => {
                start = getIndexInParent(el);
                return true;
            },
        });

        drake.on("drop", (el) => {
            end = getIndexInParent(el);
            handleReorder(start, end);
        });
    }, []);

    useEffect(() => {
        if (dataList !== undefined) {
            setDataSource([...dataList.map((info) => {
                return { ...info, key: info.id, icon:<DragOutlined type="swap" /> };
            })]);
        }
    }, [dataList]);
    if (dataList === undefined) return <div />

    const columns = [
        {
            title: <DragOutlined type="swap" />,
            dataIndex: "icon",
        },
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Thumbnail",
            dataIndex: "thumbnail_image",
            render: (text, record) => {
                if (record.thumbnail_image === null) {
                    return (
                        <img className="room-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                    )
                }
                else {
                    return (
                        <img className="room-thumbnail-img" src={record.thumbnail_image} />
                    )
                }

            },
        },
        {
            title: "Menu Name",
            dataIndex: "name",
            align: "center",
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
            },
        },
        {
            title: "Publish on User Site",
            dataIndex: "status",
            align: "center",
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status),
            },
            render: (text, record) => {
                if (record.status === "1") {
                    return <div className="active-btn" style={{ margin: 'auto' }}>Yes</div>
                }
                else {
                    return <div className="inactive-btn" style={{ margin: 'auto' }}>No</div>
                }
            }
        },
        {
            title: "Display Note",
            dataIndex: "show_note",
            align: "center",
            sorter: {
                compare: (a, b) => a.show_note.localeCompare(b.show_note),
            },
            render: (text, record) => {
                return record.show_note === "1" ? "Yes" : "No"
            },
        },
        {
            title: "Updated At",
            dataIndex: "update_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a className="edit-btn"
                            onClick={() => {
                                onUpdateAction(record)
                            }}
                        >
                            Edit
                        </a>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                            if (res == false) return;
                            onDeleteAction(record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];
    
    const handleReorder = (dragIndex, draggedIndex) => {
        setDataSource((oldState) => {
            const newState = [...oldState];
            const item = newState.splice(dragIndex, 1)[0];
            newState.splice(draggedIndex, 0, item);
            apiUpdateUserSiteItemMainSubPosition(newState)
            return newState;
        });
    };


    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidget)
