import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select, InputNumber } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';

function TableWidget(props) {
    const [isBusy, setIsBusy] = useState(false);
    const { dataList, setDataList, onUpdateAction, onDeleteAction, onDetailAction } = props;


    if (dataList === undefined) return <div />

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Thumbnail",
            dataIndex: "image_url",
            render: (text, record) => {
                if (record.image_url === null || record.image_url === "") {
                    return (
                        <img className="room-thumbnail-img" src={'/assets/icons/ic_picture.svg'} />
                    )
                }
                else {
                    return (
                        <img className="room-thumbnail-img" src={record.image_url} />
                    )
                }

            },
        },
        {
            title: "First Name",
            dataIndex: "first_name",
            align : "center",
            sorter: {
                compare: (a, b) => a.first_name.localeCompare(b.first_name),
            },
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            align : "center",
            sorter: {
                compare: (a, b) => a.last_name.localeCompare(b.last_name),
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            align : "center",
            sorter: {
                compare: (a, b) => a.email.localeCompare(b.email),
            },
        },
        {
            title: "Role",
            dataIndex: "role",
            align : "center",
            sorter: {
                compare: (a, b) => a.role.localeCompare(b.role),
            },
            render: (text, record) => {
                if (record.role === "1") {
                    return (<div>Admin</div>)
                }
                else {
                    return (<div>Chef</div>)
                }
                
            }
        },
        {
            title: "Updated At",
            dataIndex: "update_timestamp",
            align : "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align : "center",
            render: (text, record) => {
                return (
                    <div size="middle" direction={"vertical"}>
                        <a  className="edit-btn"
                            onClick={() => {
                                onUpdateAction(record)
                            }}
                        >
                            Edit
                        </a>
                        <a style={{ color: "var(--redColor)", marginLeft: 20 }} className="del-btn" onClick={async () => {
                            var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                            if (res == false) return;
                            onDeleteAction(record)
                        }}>
                            Delete
                        </a>
                    </div>
                );
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidget)
