import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { message, Modal, Col, Input, Row, Tooltip, Radio, Spin, DatePicker } from "antd";

import "./AddExpenseDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import ProductImageWidget from './ProductImageWidget';
import moment from "moment"
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import ProductDescriptionInput from 'components/ProductPage/ProductDescriptionInput/ProductDescriptionInput';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

function AddExpenseDialog(props) {
  const { show, proceed, record, title, isEditing } = props

  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (record !== undefined) {
      setData({ ...record });
    } else {
      setData({month_date:moment().startOf("month").format(dateFormat)});
    }
  }, [record]);

  function onChangeDatePicker(date, dateString) {
    if (!dateString) {
      message.info("Please select start date")
      return
    }
    var tmpData = data
    tmpData["month_date"] = date.format(dateFormat)
    
    setData({ ...tmpData }) 
}

  const validateFields = async () => {
    
    var errorList = Array();
    errorList = isEmpty(data, "name", errorList);
    errorList = isEmpty(data, "cost_per_month", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };


  return (
    <Modal
      open={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-expense-dlg"
      onOk={async () => {

        if ((await validateFields()) === false) return;

        proceed({
          ...data,
        });
      }}
    >
      <Spin spinning={isBusy}>

        <div>
          <div>Expense Avatar*</div>
          <ProductImageWidget
            field={"thumbnail_image"}
            productInfo={data}
            setProductInfo={setData}
            errorField={errorField}
            setErrorField={setErrorField}
          />
          <Row align={"middle"} gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Enter Name*</div>
              <ProductDescriptionInput
                placeholder="name"
                field={"name"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div>Monthly Cost*</div>
              <ProductInputNumber
                field={"cost_per_month"}
                productInfo={data}
                setProductInfo={setData}
                errorField={errorField}
                setErrorField={setErrorField}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: "10px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>Select Month*</div>
              <DatePicker 
                value={dayjs(data["month_date"], dateFormat)}
                onChange={onChangeDatePicker} 
                picker="month"
                size='middle' 
                style={{
                  border: errorField !== undefined && errorField.includes("month_date")
                    ? "1px solid red"
                    : "1px solid #d9d9d9",
                  borderRadius: "8px",
                  background: `var(--backgroundColor)`,
                  padding: "8px 12px",
                  width:"100%"
                }}

              />
            </Col>
          </Row>
          
        </div>
      </Spin>
    </Modal>
  );
}

export default confirmable(AddExpenseDialog)
