import { ConsoleSqlOutlined } from "@ant-design/icons";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./ajaxServices";
import { urlCreateSalary, urlDeleteSalary, urlGetSalary } from "./CONSTANTS";


export function apiCreateSalary(data) {
  const url = urlCreateSalary;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("team_member_id", data.team_member_id);
  formData.append("selected_cost_unit", data.selected_cost_unit);
  formData.append("work_time", data.work_time);
  formData.append("description", data.description);
  formData.append("salary_amount", data.salary_amount);  
  
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}

export const apiGetSalary = () => {
  const url = urlGetSalary;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiDeleteSalary(id) {
  const url = urlDeleteSalary;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}