import React from "react";
import { Col, message, Row, Spin } from 'antd';
import "./SpoilageGroup.css";
import CartTable from "./CartTable/CartTable";
import { useState, useEffect } from "react";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import { showSpoilageGroupDialog } from "./SpoilageGroupDialog/showSpoilageGroupDialog";
import { apiCreateSpoilage } from "services/spoilageService";

function SpoilageGroup(props) {
    const { dataList, RemoveSpoilageAction, ownCommonData } = props;
    const [searchList, setSearchList] = useState([]);
    const [totalBudget, setTotalBudget] = useState(0);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        setSearchList(dataList)
        getTotalBudget()
    }, [dataList]);

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText.toLowerCase()))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const getTotalBudget = () => {
        let total_buget = 0
        for (let i = 0; i < dataList.length; i++) {
            total_buget = parseFloat(total_buget) + parseFloat(dataList[i].total_price)
        }
        setTotalBudget(total_buget)
    }
    const submitSpoilage = async () => {
        if (dataList.length == 0) {
            message.error("Please Add Spoilage Item!")
            return
        }
        var res = await showSpoilageGroupDialog({
            totalBudget: totalBudget,
        });
        if (res == null) return;
        console.log("res.spoilageInfo",res.spoilageInfo)
        let data = { author_name: res.spoilageInfo['author_name'], reason: res.spoilageInfo['reason'], totalBudget: totalBudget, cartList: dataList }
        setIsBusy(true);
        apiCreateSpoilage(data)
            .then((data) => {
                setIsBusy(false);
                message.success("Successfully Submitted!")
            })
            .catch((err) => {
                message.error(err)
                setIsBusy(false);
            });
    }
    return (
        <div>
            <Spin spinning={isBusy}>

                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <TitleSearch
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                    </Col>
                    <Col xs={23} sm={23} md={23} lg={23} xl={11} >
                        <Row gutter={[16, 16]} justify="end">
                            <span style={{ margin: 10 }}>Total Spoilage Cost : $ {parseFloat(totalBudget).toFixed(2)}</span>
                            <CommonButton
                                customClass={"cart-pay-basic-unit-add-button"}
                                onClick={() => {
                                    submitSpoilage();
                                }}>
                                    Submit
                            </CommonButton>
                        </Row>
                    </Col>

                </Row>

                <div style={{ marginTop: 20 }}>
                    <CartTable
                        dataList={searchList}
                        RemoveSpoilageAction={RemoveSpoilageAction}
                        ownCommonData={ownCommonData}
                    />
                </div>
            </Spin>
        </div>
    );
}

export default SpoilageGroup;
